import React from 'react'
import rice from '../../media/rice.png'
import sugar from '../../media/sugar.png'
import blackPepper from '../../media/blackPepper.png'
import { Container } from 'reactstrap'
import Saperator from '../Saperator'
import CardCarousel from '../CardCarousel'

const jsonData = [
    {
        imgSrc: rice,
        cardTitle: 'Rice',
        cardText: 'Wide varieties of high-quality Pakistani rice, including Broken, Sella, Steam, and Super Punjab.'
    },
    {
        imgSrc: sugar,
        cardTitle: 'Sugar',
        cardText: 'ICUMSA 45 sugar with global standards for purity and sweetness.'
    },
    {
        imgSrc: blackPepper,
        cardTitle: 'Black Pepper',
        cardText: 'Fresh, aromatic black pepper of the highest quality, carefully sourced and processed.'
    },
]

const OurProducts = () => {
  return (
    <Container>
        <div id='section-ourProducts' className='section-ourProducts'>
            <div className="cc-heading-a text-center"> Our Products </div>
            <CardCarousel jsonData={jsonData} forLg={3} forSm={1} heightClass={'h-80'}/> 
        </div>
        <Saperator/>
    </Container>
  )
}

export default OurProducts