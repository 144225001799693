import React from 'react'

const Saperator = () => {
  return (
    <div className='separator'>

    </div>
  )
}

export default Saperator