import Header from "./components/Header";
import OurProducts from "./components/Sections/OurProducts";
import Section01 from "./components/Sections/Section01";
import AboutUs from "./components/Sections/AboutUs";
import OurServices from "./components/Sections/OurServices";
import OurPartners from "./components/Sections/OurPartners";
import Testimonials from "./components/Sections/Testimonials";
import ContactUs from "./components/Sections/ContactUs";
import Footer from "./components/Footer";
import Copyright from "./components/Copyright";
import AboutWork from "./components/Sections/AboutWork";


function App() {
  return (
    <>
      <Header/>
      <Section01/>
      <AboutUs/>
      <AboutWork/>
      <OurProducts/>
      <OurServices/>
      <OurPartners/>
      <Testimonials/>
      <ContactUs/>
      <Footer/>
      <Copyright/>
    </>
  );
}

export default App;
