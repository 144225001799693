import React from 'react'

const Section01 = () => {
  return (
    <div id='section01' className='section01 d-flex justify-content-center align-items-center'>
        <div className="section-content text-center">
            <div className="section-heading text-light">
              Where Grains Meet Gains
            </div>
            <div className="heading-ender"></div>
        </div>
    </div>
  )
}

export default Section01