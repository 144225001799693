import React from 'react'
import EVERGREEN from '../../media/p1.png'
import MAERSK from '../../media/p2.png'
import BALAJI from '../../media/p3.png'
import AMT from '../../media/p4.png'
import SGS from '../../media/p5.png'
import { Container } from 'reactstrap'
import Saperator from '../Saperator'

const partners = [
    {
        imgSrc: EVERGREEN,
        title: 'EVERGREEN',
    },
    {
        imgSrc: MAERSK,
        title: 'MAERSK',
    },
    {
        imgSrc: BALAJI,
        title: 'BALAJI',
    },
    {
        imgSrc: AMT,
        title: 'AMT',
    },
    {
        imgSrc: SGS,
        title: 'SGS',
    },
]

const OurPartners = () => {
  return (
    <Container>
        <div id='section-ourPartners' className='section-ourPartners'>
            <div className="cc-heading-a text-center"> Our Partners </div>
            <div className='partners'>
                {partners?.map((data, index) => (
                    <div className="partner-box" key={index}>
                        <div className="partner-content">
                            <img src={data.imgSrc} alt="Our Partner" width={'60%'}/>
                            <div className="partner-title">{data.title}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <Saperator/>
    </Container>
  )
}

export default OurPartners