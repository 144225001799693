import React from 'react'

const Copyright = () => {
  return (
    <div className='copyright'>
      <div className="text-center p-3">© 2024 QureshiVentures. All Rights Reserved.</div>
    </div>
  )
}

export default Copyright