import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from 'reactstrap'
import Saperator from '../Saperator'

const testimonials = [
    {
      review: "Qureshi Ventures ensures every shipment meets the highest global standards, certified by SGS.",
      clientName: 'SGS',
      clientDes: 'Quality Assurance Partner',
    },
    {
        review: "Working with Qureshi Ventures has been a great experience. Their commitment to quality and market expertise sets them apart, and we’re proud to support their growth.",
        clientName: ' ABASEEN',
        clientDes: 'Business Support',
    },
    {
        review: "Our partnership with Qureshi Ventures has been invaluable in expanding our market reach.",
        clientName: 'AMT',
        clientDes: 'Strategic Partner',
    },
]

const Testimonials = () => {
    const [slidesToShow, setSlidesToShow] = useState(null);

    const settings = {
      centerMode: false,
      infinite: testimonials.length > slidesToShow,
      centerPadding: '0',
      slidesToShow: Math.min(slidesToShow, testimonials.length), 
      speed: 500,
      focusOnSelect: true,
      arrows: testimonials.length > slidesToShow,
      draggable: testimonials.length > slidesToShow,
    };

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 767) {
          setSlidesToShow(1); 
        }else if (window.innerWidth <= 1024) {
          setSlidesToShow(2); 
        } else {
          setSlidesToShow(3); 
        }
      };
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

  return (
    <Container>
        <div id='section-testimonials' className='section-testimonials'>
            <div className="cc-heading-a text-center"> Testimonials </div>

            <div className='testimonials'>
              <Slider {...settings}>
                {testimonials?.map((data, index) => (
                    <div className="testimonial text-center" key={index}>
                      <div className="testimonial-content">
                            <span class="rating__star__full"><svg width="14px" height="14px" viewBox="0 0 25 25" class="icon icon-star icon-star--full"><polygon fill-rule="nonzero" points="16.2212909 8.77064179 12.5128412 0.870224269 8.80343056 8.77064179 0.512841234 10.0374085 6.51243703 16.1862739 5.09643337 24.8702243 12.5128412 20.7696377 19.9292491 24.8702243 18.5122845 16.1862739 24.5128412 10.0374085"></polygon></svg><svg width="14px" height="14px" viewBox="0 0 25 25" class="icon icon-star icon-star--full"><polygon fill-rule="nonzero" points="16.2212909 8.77064179 12.5128412 0.870224269 8.80343056 8.77064179 0.512841234 10.0374085 6.51243703 16.1862739 5.09643337 24.8702243 12.5128412 20.7696377 19.9292491 24.8702243 18.5122845 16.1862739 24.5128412 10.0374085"></polygon></svg><svg width="14px" height="14px" viewBox="0 0 25 25" class="icon icon-star icon-star--full"><polygon fill-rule="nonzero" points="16.2212909 8.77064179 12.5128412 0.870224269 8.80343056 8.77064179 0.512841234 10.0374085 6.51243703 16.1862739 5.09643337 24.8702243 12.5128412 20.7696377 19.9292491 24.8702243 18.5122845 16.1862739 24.5128412 10.0374085"></polygon></svg><svg width="14px" height="14px" viewBox="0 0 25 25" class="icon icon-star icon-star--full"><polygon fill-rule="nonzero" points="16.2212909 8.77064179 12.5128412 0.870224269 8.80343056 8.77064179 0.512841234 10.0374085 6.51243703 16.1862739 5.09643337 24.8702243 12.5128412 20.7696377 19.9292491 24.8702243 18.5122845 16.1862739 24.5128412 10.0374085"></polygon></svg><svg width="14px" height="14px" viewBox="0 0 25 25" class="icon icon-star icon-star--full"><polygon fill-rule="nonzero" points="16.2212909 8.77064179 12.5128412 0.870224269 8.80343056 8.77064179 0.512841234 10.0374085 6.51243703 16.1862739 5.09643337 24.8702243 12.5128412 20.7696377 19.9292491 24.8702243 18.5122845 16.1862739 24.5128412 10.0374085"></polygon></svg></span>
                            <div className="review my-3"> <i>"{data.review}"</i> </div>
                            <div className="clientName cc-primary-color ">{data.clientName}</div>
                            <div className="clientDes cc-small-text">{data.clientDes}</div>
                      </div>
                    </div>
                ))}
              </Slider>
            </div>
        </div>
        {/* <Saperator/> */}
    </Container>
  )
}

export default Testimonials