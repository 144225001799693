import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

const CardCarousel = ({jsonData, forLg= 3, forMd=2, forSm= 1, heightClass}) => {
    const [slidesToShow, setSlidesToShow] = useState(null);

    const settings = {
      centerMode: false,
      infinite: jsonData.length > slidesToShow,
      centerPadding: '0',
      slidesToShow: Math.min(slidesToShow, jsonData.length), 
      speed: 500,
      focusOnSelect: true,
      arrows: jsonData.length > slidesToShow,
      draggable: jsonData.length > slidesToShow,
    };
    

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth <= 767) {
            setSlidesToShow(forSm); 
          }else if (window.innerWidth <= 1024) {
            setSlidesToShow(forMd); 
          } else {
            setSlidesToShow(forLg); 
          }
        };
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

  return (
    <>

     <div className="cardCarousel">
      <Slider {...settings}>
        {jsonData.map((data, index) => (
          <div className='px-2'>
            <Card key={index}>
              <img alt="Card" src={data.imgSrc} height={'270px'}/>
              <CardBody>
                  <CardTitle className='cc-primary-color' tag="h5">{data.cardTitle}</CardTitle>
                  <CardText className={`cc-small-text ${heightClass}`}>{data.cardText}</CardText>
              </CardBody>
            </Card>
          </div>
        ))}
      </Slider>
     </div>

    </>
  )
}

export default CardCarousel        