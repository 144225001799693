import React from 'react'
import wwd from '../../media/wwd.webp'
import {Container} from 'reactstrap'
import Saperator from '../Saperator'

const AboutWork = () => {
  return (
    <Container>
        <div id='section-aboutWork' className='section-aboutWork about-section'>
            <div className="section-content">
                <div className="section-heading cc-primary-color"> What We Do </div>
                <div className="section-description cc-small-text"> 
                    Qureshi Ventures facilitates the global trade of essential food commodities, ensuring quality and reliability from Pakistan to your doorstep.
                    Our core focus lies in delivering high-quality staples such as rice, sugar, and black pepper from the heart of Pakistan to markets around the world. <br/><br/>
                    We manage every aspect of the export process, from sourcing and compliance to logistics and delivery, partnering with trusted names to provide a seamless trading experience. Our commitment to quality and excellence makes us a trusted partner in the global market.
                </div>
            </div>
            <img src={wwd} alt={'What We Do'} />
        </div>
        <Saperator/>
    </Container>
  )
}

export default AboutWork