import React, { useEffect, useState } from 'react';
import logo from '../media/logo.png';
import { Button } from 'reactstrap';

const Header = () => {
  const [bgColor, setBgColor] = useState('transparent');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setBgColor('#003060');
      } else {
        setBgColor('transparent');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="header-bar" className="header-bar px-4 py-3 d-flex justify-content-between" style={{ backgroundColor: bgColor}} >
      <div className='d-flex align-items-center'>
        <img src={logo} alt={'Qureshi Ventures'} className='logo'/>
        <span className="title text-light p-0">Qureshi Ventures</span>
      </div>
      <Button outline className='contact-button p-0'><a href="#section-contactUs">Contact Us</a></Button>
    </div>
  );
};

export default Header;
