import React from 'react'
import grain from '../../media/grain.webp'
import {Container} from 'reactstrap'
import Saperator from '../Saperator'

const AboutUs = () => {
  return (
    <Container>
        <div id='section-aboutUs' className='section-aboutUs about-section'>
            <img src={grain} alt={'Who We Are'} />
            <div className="section-content">
                <div className="section-heading cc-primary-color"> Who We Are </div>
                <div className="section-description cc-small-text"> 
                    Founded in Pakistan, Qureshi Ventures specialise in the import and export of premium food products. <br/><br/>
                    Over the years, we have evolved into a trusted partner for businesses seeking top-tier food products. Our commitment to quality, reliability, and customer satisfaction has fueled our growth and solidified our reputation in the industry. <br/><br/>
                    Our success is built on strong partnerships with leading shipping companies and a dedication to excellence. 
                </div>
            </div>
        </div>
        <Saperator/>
    </Container>
  )
}

export default AboutUs