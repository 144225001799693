import React from 'react'
import Logistics from '../../media/Logistics.webp'
import QA from '../../media/QA.png'
import Packaging from '../../media/Packaging.png'
import Custom from '../../media/Custom.png'
import { Container } from 'reactstrap'
import Saperator from '../Saperator'
import CardCarousel from '../CardCarousel'

const jsonData = [
    {
        imgSrc: Logistics,
        cardTitle: 'Logistics & Supply chain',
        cardText: 'Seamless global shipping through our partnerships with leading companies, ensuring timely and secure delivery worldwide.'
    },
    {
        imgSrc: QA,
        cardTitle: 'Quality Assurance',
        cardText: 'Quality control through DNA testing and SGS certification, guaranteeing authenticity and consistency.'
    },
    {
        imgSrc: Packaging,
        cardTitle: 'Custom Packaging',
        cardText: 'Personalized packaging solutions tailored to meet specific market needs, ensuring product safety, presentation, and compliance.'
    },
    {
        imgSrc: Custom,
        cardTitle: 'Regulatory Compliance',
        cardText: 'Expert support navigating international trade regulations, ensuring smooth and hassle-free exports.'
    },
]

const OurServices = () => {
  return (
    <Container>
        <div id='section-ourServices' className='section-ourServices'>
            <div className="cc-heading-a text-center"> Our Services </div>
            <CardCarousel jsonData={jsonData} forLg={4} forSm={1} heightClass={'h-120'}/>
        </div>
        <Saperator/>
    </Container>
  )
}

export default OurServices