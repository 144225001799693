import React, { useRef, useState } from 'react';
import { Col, Container, FormGroup, Input, Row } from 'reactstrap';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const form = useRef();
  const [buttonText, setButtonText] = useState("Send");

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonText("Sending...");

    emailjs
      .sendForm('service_ztunfvs', 'template_lg2lh0f', form.current, '9AksYUJ71_RvSNic5')
      .then(
        () => {
          window.alert('Message sent successfully!');
          form.current.reset();
          setButtonText("Send");
        },
        (error) => {
          window.alert('Failed to send message, please contact the developer.');
          console.error('FAILED...', error.text);
          setButtonText("Send");
        },
      );
  };

  return (
    <Container>
        <div id='section-contactUs' className='section-contactUs'>
            <div className="cc-heading-a text-center"> Contact Us </div>
            <div className="form-block">
              <form ref={form} onSubmit={sendEmail}>
                <Row className='justify-content-center'>
                  <Col md={6}>
                    <FormGroup>
                      <Input type="text" autoComplete="off" name="fullName" placeholder='Full Name' required/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col md={6}>
                    <FormGroup>
                      <Input type="email" autoComplete="off" name="emailAddress" placeholder='Email Address' required/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col md={6}>
                    <FormGroup>
                      <Input type="text" autoComplete="off" name="contactNumber" placeholder='Contact Number' required/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col md={6}>
                    <FormGroup>
                      <Input type="textarea" autoComplete="off" name="message" placeholder='Your Message' required/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col md={6}>
                    <FormGroup>
                      <Input type="submit" value={buttonText} disabled={buttonText === "Sending..."} />
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </div>
        </div>
    </Container>
  );
};

export default ContactUs